import { Injectable, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IGuardian,
  IPagination,
  IResponse,
  IStudentListItem,
  IStudentQueryParams,
  Idropdown,
} from 'src/app/shared/interfaces';
import {
  IStudent,
  IStudentPayload,
  IPaginatedResponse,
} from '@shared/interfaces';
import { Gender, ResourceStatus, UserType } from '@shared/enums';
import { FeedbackService } from '@shared/services/feedback.service';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import { InfiniteScrollCustomEvent } from '@ionic/angular/standalone';
import { AuthService } from '@auth/auth.service';
import { UserInfo } from '@auth/model';
import { LayoutService } from '@layout/layout.service';

@Injectable({
  providedIn: 'root',
})
export class StudentsService {
  private feedbackService = inject(FeedbackService);
  private layoutService = inject(LayoutService);
  private http = inject(HttpClient);
  private translocoService = inject(TranslocoService);
  private toaster = inject(ToastrService);
  private authService = inject(AuthService);
  BE_API_BASE_URL = environment.BE_API_BASE_URL;

  private _guardiansDropdownList = signal<Idropdown[]>([]);
  readonly guardiansDropdownList = this._guardiansDropdownList.asReadonly();
  private _guardiansPagination = signal<IPagination | undefined>(undefined);
  readonly guardiansPagination = this._guardiansPagination.asReadonly();
  private _guardiansListSearchText = signal<string | undefined>(undefined);

  private studentsSignal = signal<IStudentListItem[]>([]);
  readonly studentsList = this.studentsSignal.asReadonly();

  private studentsLoadingSignal = signal<boolean>(false);
  readonly studentsLoading = this.studentsLoadingSignal.asReadonly();

  private studentsPaginationSignal = signal<IPagination | null>(null);
  readonly studentsPagination = this.studentsPaginationSignal.asReadonly();

  updateGuardiansListSearchText(v: string | undefined) {
    this._guardiansListSearchText.set(v);
  }

  getGuardiansList(
    params?: IStudentQueryParams,
    update = false,
    event?: InfiniteScrollCustomEvent,
  ) {
    return this.http
      .get<IPaginatedResponse<IGuardian[]>>(
        `${this.BE_API_BASE_URL}/guardians`,
        {
          params: {
            ...params,
            ...(this._guardiansListSearchText() && {
              searchText: this._guardiansListSearchText(),
            }),
          },
        },
      )
      .subscribe({
        next: (res) => {
          if (update) {
            const nextList: any = res.data.map((n) => ({
              value: n.id,
              displayedValue: n.fullName,
              ...n,
            }));
            this._guardiansDropdownList.update((list) => {
              return list.concat(nextList);
            });
            event?.target.complete();
          } else {
            const list: any = res.data.map((n) => ({
              value: n.id,
              displayedValue: n.fullName,
              ...n,
            }));
            list.unshift({
              value: -1,
              displayedValue: this.translocoService.translate([
                'action.guardian.new.add',
              ]),
            });
            this._guardiansDropdownList.set(list);
          }
          this._guardiansPagination.set(res.paginate);
        },
        error: (err) => {
          this._guardiansDropdownList.set([
            {
              value: -1,
              displayedValue: this.translocoService.translate([
                'action.guardian.new.add',
              ]),
            },
          ]);
          this._guardiansPagination.set(err.error.paginate);
        },
      });
  }

  /**
   * Creates a new student.
   * @param student - The student payload.
   * @returns An observable that emits the response from the server.
   */
  createStudent(student: IStudentPayload): Observable<any> {
    return this.http.post(`${this.BE_API_BASE_URL}/students`, student);
  }

  /**
   * Updates a student with the specified ID.
   * @param studentId - The ID of the student to update.
   * @param student - The partial payload containing the updated student data.
   * @returns An Observable that emits the response from the server.
   */
  updateStudent(
    studentId: string,
    student: Partial<IStudentPayload>,
  ): Observable<any> {
    return this.http.put(
      `${this.BE_API_BASE_URL}/students/${studentId}`,
      student,
    );
  }

  /**
   * Retrieves a student by their ID.
   * @param id - The ID of the student to retrieve.
   * @returns An Observable that emits the student data.
   */
  getStudent(id: number | string) {
    return this.http
      .get<IResponse<IStudent>>(`${this.BE_API_BASE_URL}/students/${id}`)
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  /**
   * Links a guardian to a student.
   *
   * @param studentId - The ID of the student.
   * @param guardianId - The ID of the guardian.
   * @returns An Observable that emits the response from the server.
   */
  linkGuardian(studentId: string, guardianId: string): Observable<any> {
    return this.http.put(
      `${this.BE_API_BASE_URL}/students/${studentId}/guardians/${guardianId}/link`,
      {},
    );
  }

  /**
   * Unlinks a guardian from a student.
   * @param studentId The ID of the student.
   * @param guardianId The ID of the guardian.
   * @returns An Observable that emits the result of the unlink operation.
   */
  unlinkGuardian(studentId: string, guardianId: string): Observable<any> {
    return this.http.put(
      `${this.BE_API_BASE_URL}/students/${studentId}/guardians/${guardianId}/unlink`,
      {},
    );
  }

  /**
   * Deactivates a student by updating their status to 'INACTIVE'.
   * @param studentId - The ID of the student to deactivate.
   * @returns An Observable that emits the response from the server.
   */
  deactivateStudent(studentId: string): Observable<any> {
    return this.http.put(
      `${this.BE_API_BASE_URL}/students/${studentId}/status`,
      {
        status: 'INACTIVE',
      },
    );
  }

  /**
   * Activates a student by updating their status to 'ACTIVE'.
   * @param studentId - The ID of the student to activate.
   * @returns An Observable that emits the response from the server.
   */
  activateStudent(studentId: string): Observable<any> {
    return this.http.put(
      `${this.BE_API_BASE_URL}/students/${studentId}/status`,
      {
        status: 'ACTIVE',
      },
    );
  }

  getStudentsList(params?: IStudentQueryParams, pathSuffix?: string) {
    this.layoutService.showProgressBar();
    this.studentsLoadingSignal.set(true);
    return this.fetchStudents(params, pathSuffix).pipe(
      map((res) => {
        this.populateStudents(this.mapStudentsToStudentListItems(res.data));
        this.studentsPaginationSignal.set(res.paginate);
        return res;
      }),
      catchError((err) => {
        if (err.status === 404 && err.error.paginate.totalItems === 0) {
          this.populateStudents([]);
        }
        this.studentsLoadingSignal.set(false);
        this.layoutService.hideProgressBar();
        this.studentsPaginationSignal.set(err.error.paginate);
        return throwError(() => new Error(err));
      }),
    );
  }

  fetchStudents(params: IStudentQueryParams | undefined, pathSuffix?: string) {
    const endpoint = pathSuffix
      ? `${this.BE_API_BASE_URL}/${pathSuffix}`
      : `${this.BE_API_BASE_URL}/students`;
    return this.http.get<IPaginatedResponse<IStudent[]>>(endpoint, {
      params: {
        ...params,
      },
    });
  }

  populateStudents(v: IStudentListItem[]) {
    this.studentsSignal.set(v);
    this.studentsLoadingSignal.set(false);
    this.layoutService.hideProgressBar();
  }

  mapStudentsToStudentListItems(students: IStudent[]): IStudentListItem[] {
    return students.map((student) => {
      return {
        id: student.id,
        nationalId: student.nationalId,
        phoneNumber: student.phoneNumber
          ? student.countryCode + ' ' + student.phoneNumber
          : '-',
        fullName: student.fullName,
        dateOfBirth: student.dateOfBirth,
        pioneerId: student.pioneerId,
        passportNumber: student.passportNumber,
        passportExpiryDate: student.passportExpiryDate,
        registrationDate: student.registrationDate,
        nationalityId: student.nationalityId?.toString(),
        nationalityName: student.nationality?.name.toString(),
        levelId: student.levels?.name,
        schoolId: student.school?.name,
        campusId: student.campus?.name,
        companyId: student.company?.name,
        gender: student.gender as Gender,
        school: student.school?.name,
        level: student.levels?.name,
        class: student.classes?.[0]?.name,
        status: student.status as ResourceStatus,
        actions: '',
        classId: student.classes?.[0]?.name,
        guardian: student.guardians?.map((g) => g.fullName).join(', '),
        guardians: student.guardians,
        userId: student.userId,
        academicYearId: student.academicYear?.name,
        lastActive: student.userEvent ? student.userEvent[0]?.createdAt : null,
      };
    });
  }

  /* shared methods between listing and profile pages */
  onDeactivateStudent = async (onConfirm: () => void) => {
    await this.feedbackService.openFeedbackModal(
      {
        type: 'warning',
        modalTitle: this.translocoService.translate(
          'global.deactivate_account.title',
        ),
        modalMessage: this.translocoService.translate(
          'user_management.deactivate_account_alert.txt',
        ),
        primaryBtnStr: this.translocoService.translate(
          'global.yes_deactivate.btn',
        ),
        secondaryBtnStr: this.translocoService.translate(
          'global.no_cancel.btn',
        ),
      },
      () => onConfirm(),
    );
  };
  async onDeactivateStudentSuccess() {
    this.toaster.success(
      '',
      this.translocoService.translate('global.successfully_deactivated.txt'),
    );
  }
  async onDeactivateStudentError(onConfirm: () => void) {
    await this.feedbackService.openFeedbackModal(
      {
        type: 'error',
        modalTitle: this.translocoService.translate('global.wrong_msg.title'),
        modalMessage: this.translocoService.translate(
          'user_management.unsuccessfully_deactivating_account.txt',
        ),
        primaryBtnStr: this.translocoService.translate('global.try_again.btn'),
        secondaryBtnStr: this.translocoService.translate('global.cancel.btn'),
      },
      () => onConfirm(),
    );
  }
  async onActivateStudent(onConfirm: () => void) {
    await this.feedbackService.openFeedbackModal(
      {
        type: 'warning',
        modalTitle: this.translocoService.translate(
          'global.activate_account.title',
        ),
        modalMessage: this.translocoService.translate(
          'user_management.activate_account_alert.txt',
        ),
        primaryBtnStr: this.translocoService.translate(
          'global.yes_activate.btn',
        ),
        secondaryBtnStr: this.translocoService.translate(
          'global.no_cancel.btn',
        ),
      },
      () => onConfirm(),
    );
  }
  async onActivateStudentSuccess() {
    this.toaster.success(
      '',
      this.translocoService.translate(
        'user_management.account.activation.success',
      ),
    );
  }

  async onActivateStudentError(onConfirm: () => void) {
    await this.feedbackService.openFeedbackModal(
      {
        type: 'error',
        modalTitle: this.translocoService.translate('global.wrong_msg.title'),
        modalMessage: this.translocoService.translate(
          'user_management.unsuccessfully_activating_account.txt',
        ),
        primaryBtnStr: this.translocoService.translate('global.try_again.btn'),
        secondaryBtnStr: this.translocoService.translate('global.cancel.btn'),
      },
      () => onConfirm(),
    );
  }

  getTeachersByStudentId(id: number) {
    const userData: UserInfo = this.authService.user()!;
    const endpoint =
      userData.type == UserType.STUDENT
        ? `${this.BE_API_BASE_URL}/students/${id}/teachers`
        : `${this.BE_API_BASE_URL}/students/${userData.userTypeId}/${id}/teachers`;
    return this.http.get<IResponse<any>>(endpoint).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  /**
   * Change password for a student
   * @param id Student ID
   * @param payload Password change payload containing new and old password
   */
  changePassword(
    id: string,
    payload: { password: string; oldPassword?: string },
  ) {
    return this.http
      .put<
        IResponse<any>
      >(`${this.BE_API_BASE_URL}/students/${id}/change-password`, payload)
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }
}
